const initialState = {
  addNewCard: {
    isShow: false,
    data: null,
  },
  deleteCard: {
    isShow: false,
    data: null,
  },
  confirmationCVV: {
    isShow: false,
    data: null,
  },
  promotion: {
    isShow: false,
    data: null,
  },
  promotionDetail: {
    isShow: false,
    data: null,
  },
  manageCreditCard: {
    isShow: false,
    data: null,
  },
  NumberConfirmation: {
    isShow: false,
    data: null,
  },
  kredivoInstallment: {
    isShow: false,
    data: null,
  },
  changePhoneNumber: {
    isShow: false,
    data: null,
  },
  sortBy: {
    isShow: false,
    data: null,
  },
  filters: {
    isShow: false,
    data: null,
  },
  aboutBrand: {
    isShow: false,
    data: null,
  },
  loading: {
    isShow: false,
    data: null,
  },
  addToCart: {
    isShow: false,
    data: null,
  },
  productAdded: {
    isShow: false,
    data: null,
  },
  orderRetry: {
    isShow: false,
    data: null,
  },
  ordersFilter: {
    isShow: false,
    data: null,
  },
  userQR: {
    isShow: false,
    data: null,
  },
  childrenList: {
    isShow: false,
    data: null,
  },
  previewImage: {
    isShow: false,
    data: null,
  },
  trackOrder: {
    isShow: false,
    data: null,
  },
  notifyMe: {
    isShow: false,
    data: null,
  },
  notifyMeSuccess: {
    isShow: false,
    data: null,
  },
  packageReceived: {
    isShow: false,
    data: null,
  },
  packageReceivedSuccess: {
    isShow: false,
    data: null,
  },
  calendar: {
    isShow: false,
    data: null,
  },
  previewEgift: {
    isShow: false,
    data: null,
  },
  confirmationEgift: {
    isShow: false,
    data: null,
  },
  moreReviews: {
    isShow: false,
    data: null,
  },
  filterSkinType: {
    isShow: false,
    data: null,
  },
  reviewSortBy: {
    isShow: false,
    data: null,
  },
  filterReviewRating: {
    isShow: false,
    data: null,
  },
  filterAge: {
    isShow: false,
    data: null,
  },
  userPhotos: {
    isShow: false,
    data: [],
  },
  campaignVoucherDetail: {
    isShow: false,
    data: null,
  },
  campaignVideoDetail: {
    isShow: false,
    data: null,
  },
  campaignProductDetail: {
    isShow: false,
    data: null,
  },
  consent: {
    isShow: false,
    data: null,
  },
  brandCategories: {
    isShow: false,
    data: null,
  },
  confirmPublishReview: {
    isShow: false,
    data: null,
  },
  confirmEditReview: {
    isShow: false,
    data: null,
  },
  discardReview: {
    isShow: false,
    data: null,
  },
  addPhoto: {
    isShow: false,
    data: null,
  },
  actionReview: {
    isShow: false,
    data: null,
  },
  reportReview: {
    isShow: false,
    data: null,
  },
  requestToDeleteReview: {
    isShow: false,
    data: null,
  },
  discardAddPhoto: {
    isShow: false,
    data: null,
  },
  filterReviews: {
    isShow: false,
    data: null,
  },
  reviewType: {
    isShow: false,
    data: null,
  },
  dateOfBirth: {
    isShow: false,
    data: null,
  },
  completedProfile: {
    isShow: false,
    data: null,
  },
  successShareFAQ: {
    isShow: false,
    data: null,
  },
  login: {
    isShow: false,
    data: null,
  },
  productOos: {
    isShow: false,
    data: null,
  },
  verifyAccount: {
    isShow: false,
    data: null,
  },
  cropPhoto: {
    isShow: false,
    data: null,
  },
  errorPage: {
    isShow: false,
    data: null,
  },
  filterDatePicker: {
    isShow: false,
    data: null,
  },
  egiftMessageTemplates: {
    isShow: false,
    data: null,
  },
  egiftCropPhoto: {
    isShow: false,
    data: null,
  },
  addReviewRecentlyBought: {
    isShow: false,
    data: null,
  },
  datepicker: {
    isShow: false,
    data: null,
  },
  ModalDetailOrder: {
    isShow: false,
    data: null,
  },
  listCountries: {
    isSHow: false,
    data: null,
  },
  deleteAddress: {
    isShow: false,
    data: null,
  },
  removeProductBabyRegistry: {
    isShow: false,
    data: null,
  },
  shareBabyRegistry: {
    isShow: false,
    data: null,
  },
  selectDelivery: {
    isShow: false,
    data: null,
  },
  calendarRegistry: {
    isShow: false,
    data: null,
  },
  dynamicBanner: {
    isShow: false,
    data: null,
  },
  addToBagRegistryWarning: {
    isShow: false,
    data: null,
  },
  addToBagPreOrderWarning: {
    isShow: false,
    data: null,
  },
  addRegistry: {
    isShow: false,
    data: null,
  },
  requestPermission: {
    isShow: false,
    data: null,
  },
  reportComment: {
    isShow: false,
    data: null,
  },

  requestToDeleteArticle: {
    isShow: false,
    data: null,
  },
  installment: {
    isShow: false,
    data: null,
  },
  modalHowToPay: {
    isShow: false,
    data: null,
  },
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MODAL/HIDE':
      return {
        ...state,
        [action.modalName]: {
          ...state[action.modalName],
          data: action.data,
          isShow: false,
        },
      };
    case 'MODAL/SHOW':
      return {
        ...state,
        [action.modalName]: {
          ...state[action.modalName],
          data: action.data,
          isShow: true,
        },
      };
    default:
      return state;
  }
};

export default modalReducer;
